
import { defineStore } from "pinia";
import CartItem from '@/types/CartItem';

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        cart: [] as Array<CartItem>,
        showPopupCart: false as boolean | undefined,
        showPopupCloseModal: false as boolean | undefined,
    }),
    actions: {
        async getCart() {
            if (import.meta.client) {
                let cartStorage = window?.localStorage?.getItem('cart');
                this.cart = cartStorage ? JSON.parse(cartStorage) : [];
            }
        },
        addCartItem(cartItem: CartItem) {
            this.cart.push(cartItem);
            this.saveCart();
        },
        getCartItem(cartItemGuid: string): CartItem | undefined {
            return this.cart.find((c) => c.cartItemGuid === cartItemGuid);
        },
        clearCart(): void {
            this.cart = [];
            this.saveCart();
        },
        saveCart() {
            if (import.meta.client) {
                window?.localStorage?.setItem('cart', JSON.stringify(this.cart));
            }
        },
        updateCartItem(cartItemToUpdate: CartItem): void {
            const indexToChange = this.cart.findIndex((c) => c.cartItemGuid === cartItemToUpdate.cartItemGuid);
            if (indexToChange > -1) {
                this.cart.splice(indexToChange, 1, cartItemToUpdate);
            }
            this.saveCart();
        },
        removeCartItem(cartItemGuid: string) {
            let index = this.cart.findIndex(c => c.cartItemGuid == cartItemGuid);
            if (index > -1) {
                this.cart.splice(index, 1);
            }
            this.saveCart();
        },
        toggleShowCart(): void {
            this.showPopupCart = !this.showPopupCart;
        },
        showCloseModal(): void {
            this.showPopupCloseModal = !this.showPopupCloseModal;
        },
        count() { return this.cart.length; }
    }
})
